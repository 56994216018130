import React from "react";
import { Link } from "gatsby";

import imgC from "../../assets/image/l1/jpg/content-1-img2.jpg";
import imgM1 from "../../assets/image/l1/png/media-img-1.png";
import imgM2 from "../../assets/image/l1/png/media-img-2.png";
import imgM3 from "../../assets/image/l1/png/media-img-3.png";

const JoinUs = () => {
  return (
    <>
      {/* <!-- Content Area -->  */}
      <section className="py-7 py-lg-13">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-xl-6 col-lg-6 col-md-8 col-xs-10"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="position-relative pr-lg-20 pr-md-15 pr-9">
                {/* <!-- content img start --> */}
                <img src={imgC} alt="" className="w-100 rounded-4" />
                {/* <!-- content img end --> */}
              </div>
            </div>
            <div
              className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-11"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              {/* <!-- content-2 start --> */}
              <div className="content-2 pl-xl-10 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-xl-10 pr-xxl-0">
                {/* <!-- content-2 section title start --> */}
                {/* <p className="text-dodger font-size-4 font-weight-semibold mb-8">
                  Looking for an expert for your company?
                </p> */}
                <h2 className="font-size-9 mb-8">
                  What we are looking
                </h2>
                <p className="text-default-color font-size-5 mb-12">
                  We are looking for people who are
                </p>
                {/* <!-- content-2 section title end --> */}
                {/* <!-- content-1 list-group start --> */}
                <ul className="list-unstyled pl-0">
                  <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                    <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Enjoy learning new things
                  </li>
                  <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                    <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Having fun building impactful software
                  </li>
                  <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                    <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Like challenges
                  </li>
                </ul>
                {/* <!-- content-1 list-group end --> */}
                <a
                  href="https://forms.gle/y6XTbtyeYUvzhSrW8"
                  target="_blank"
                  className="btn btn-green btn-h-60 text-white w-180 rounded-5 text-uppercase"
                >
                  Join Us
                </a>
              </div>
              {/* <!-- content-2 end --> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinUs;
