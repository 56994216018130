import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/internship/Hero";
import Steps from "../sections/internship/Steps";
import WithUs from "../sections/internship/WithUs";
import FeaturedJobs from "../sections/internship/FeaturedJobs";
import YouWillGet from "../sections/internship/YouWillGet";
import JoinUs from "../sections/internship/JoinUs";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          bgClass: "dynamic-sticky-bg",
        }}
      >
        <Hero />
        <WithUs />
        {/* <FeaturedJobs /> */}
        <YouWillGet />
        {/* <Steps /> */}
        <JoinUs />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
